import React from "react";

function ResumeContent(props) {
  const contentArray = Array.isArray(props.content)
    ? props.content
    : [props.content];

  return (
    <div className="resume-item">
      <h2 className={props.title ? "resume-title" : "resume-no-title"}>
        {props.title}
      </h2>
      <span className="date" style={{ color: props.theme.text }}>
        <em>{props.date}</em>
      </span>
      <h6
        className={props.title ? "resume-subtitle" : "resume-no-title"}
        style={{ color: props.theme.text }}
      >
        {props.subtitle}
      </h6>
      <p></p>
      <ul style={{ color: props.theme.text }}>
        {contentArray.map((value) => (
          <li key={value}> ‣ {value}</li>
        ))}
      </ul>
    </div>
  );
}

export default ResumeContent;
