/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/robot.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, -0.09, -0.75]}>
        <primitive object={nodes.root} />
        <primitive object={nodes["MCH-hand_pole_ik_socketR"]} />
        <primitive object={nodes["MCH-hand_ik_socketR"]} />
        <primitive object={nodes["MCH-hand_pole_ik_socketL"]} />
        <primitive object={nodes["MCH-hand_ik_socketL"]} />
        <primitive object={nodes["MCH-foot_pole_ik_socketR"]} />
        <primitive object={nodes["MCH-foot_ik_socketR"]} />
        <primitive object={nodes["MCH-foot_pole_ik_socketL"]} />
        <primitive object={nodes["MCH-foot_ik_socketL"]} />
        <skinnedMesh
          geometry={nodes.hair.geometry}
          material={nodes.hair.material}
          skeleton={nodes.hair.skeleton}
        />
        <skinnedMesh
          geometry={nodes.kemeja.geometry}
          material={nodes.kemeja.material}
          skeleton={nodes.kemeja.skeleton}
        />
        <skinnedMesh
          geometry={nodes.me.geometry}
          material={materials.body}
          skeleton={nodes.me.skeleton}
        />
        <skinnedMesh
          geometry={nodes.me002.geometry}
          material={nodes.me002.material}
          skeleton={nodes.me002.skeleton}
        />
      </group>
      <mesh
        geometry={nodes.Cube005.geometry}
        material={materials.laptop_tex}
        position={[-0.03, -0.47, 0.44]}
      />
      <mesh
        geometry={nodes.bool1185.geometry}
        material={materials.Material}
        position={[2.4, -0.43, -0.75]}
      />
    </group>
  );
}

useGLTF.preload("/robot.gltf");
