import React from "react";
import "./Footer.css";
import { Fade } from "react-awesome-reveal";
import { greeting } from "../../portfolio.js";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "./ToggleSwitch";

export default function Footer(props) {
  const { t } = useTranslation();
  return (
    <Fade>
      <p className="footer-text" style={{ color: props.theme.text }}>
        {t("footer_msg")} {greeting.sub} &copy; {new Date().getFullYear()}
      </p>
      <div className="footer-content">
        <ToggleSwitch theme={props.theme} onToggle={props.onToggle} />
      </div>
    </Fade>
  );
}
