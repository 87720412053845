import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Certifications from "../../containers/certifications/Certifications";
import CompetitiveSites from "../../components/competitiveSites/CompetitiveSites";
import EducationImg from "./EducationImg";
import { competitiveSites } from "../../portfolio";
import "./EducationComponent.css";
import { Fade } from "react-awesome-reveal";
import Resume from "./Resume";
import { withTranslation } from "react-i18next";

class Education extends Component {
  render() {
    const theme = this.props.theme;
    const { t } = this.props;
    return (
      <div className="education-main">
        <Header
          theme={this.props.theme}
          onToggle={this.props.onThemeToggle}
          onLanguageChange={this.props.onLanguageChange}
        />
        <div className="basic-education">
          <Fade bottom duration={2000} distance="40px">
            <div className="heading-div">
              <div className="heading-img-div">
                <EducationImg
                  theme={theme}
                  onToggle={this.props.onThemeToggle}
                />
              </div>
              <div className="heading-text-div">
                <h1 className="heading-text" style={{ color: theme.orange }}>
                  {t("nav_02")}
                </h1>
                <h3 className="heading-sub-text" style={{ color: theme.text }}>
                  {t("exp_01")}
                </h3>
                <CompetitiveSites
                  logos={competitiveSites.competitiveSites}
                  theme={this.props.theme}
                  onToggle={this.props.onThemeToggle}
                />
              </div>
            </div>
          </Fade>
          <Resume
            theme={this.props.theme}
            onToggle={this.props.onThemeToggle}
          />
          <Certifications
            theme={this.props.theme}
            onToggle={this.props.onThemeToggle}
          />
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onThemeToggle} />
        <TopButton
          theme={this.props.theme}
          onToggle={this.props.onThemeToggle}
        />
      </div>
    );
  }
}

export default withTranslation()(Education);
