import React, { Component } from "react";
import { Route, Switch, Router } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Splash from "../pages/splash/Splash";
import Education from "../pages/EduEx/EducationComponent";
import Contact from "../pages/contact/ContactComponent";
import Projects from "../pages/projects/Projects";
import { settings } from "../portfolio.js";
import { createBrowserHistory } from "history";

import ReactGA from "react-ga";
export const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.pageview(location.pathname);
});

export default class Main extends Component {
  state = {
    language: localStorage.getItem("language") || "es",
  };

  render() {
    const prefix = this.state.language === "es" ? "/es" : "/en";

    const getRoutePath = (path) => {
      return `${prefix}${path}`;
    };

    if (settings.isSplash) {
      return (
        <div>
          <Router basename="/" history={history}>
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => (
                  <Splash
                    {...props}
                    theme={this.props.theme}
                    language={this.state.language}
                  />
                )}
              />
              <Route
                path={getRoutePath("/home")}
                render={(props) => (
                  <Home
                    {...props}
                    theme={this.props.theme}
                    onLanguageChange={(newLanguage) =>
                      this.setState({ language: newLanguage })
                    }
                    language={this.state.language}
                    onThemeToggle={this.props.onThemeToggle}
                  />
                )}
              />
              <Route
                path={getRoutePath("/education")}
                render={(props) => (
                  <Education
                    {...props}
                    theme={this.props.theme}
                    onLanguageChange={(newLanguage) =>
                      this.setState({ language: newLanguage })
                    }
                    onThemeToggle={this.props.onThemeToggle}
                  />
                )}
              />
              <Route
                path={getRoutePath("/contact")}
                render={(props) => (
                  <Contact
                    {...props}
                    theme={this.props.theme}
                    onLanguageChange={(newLanguage) =>
                      this.setState({ language: newLanguage })
                    }
                    onThemeToggle={this.props.onThemeToggle}
                  />
                )}
              />
              <Route
                exact
                path="/splash"
                render={(props) => (
                  <Splash
                    {...props}
                    theme={this.props.theme}
                    language={this.state.language}
                  />
                )}
              />
              <Route
                path={getRoutePath("/projects")}
                render={(props) => (
                  <Projects
                    {...props}
                    theme={this.props.theme}
                    onLanguageChange={(newLanguage) =>
                      this.setState({ language: newLanguage })
                    }
                    onThemeToggle={this.props.onThemeToggle}
                  />
                )}
              />
            </Switch>
          </Router>
        </div>
      );
    } else {
      return (
        <div>
          <Router basename="/">
            <Switch>
              <Route
                path={getRoutePath("/")}
                exact
                render={(props) => (
                  <Home
                    {...props}
                    theme={this.props.theme}
                    onLanguageChange={(newLanguage) =>
                      this.setState({ language: newLanguage })
                    }
                    language={this.state.language}
                    onThemeToggle={this.props.onThemeToggle}
                  />
                )}
              />
              <Route
                path={getRoutePath("/home")}
                render={(props) => (
                  <Home
                    {...props}
                    theme={this.props.theme}
                    onLanguageChange={(newLanguage) =>
                      this.setState({ language: newLanguage })
                    }
                    language={this.state.language}
                    onThemeToggle={this.props.onThemeToggle}
                  />
                )}
              />
              <Route
                path={getRoutePath("/education")}
                render={(props) => (
                  <Education
                    {...props}
                    theme={this.props.theme}
                    onLanguageChange={(newLanguage) =>
                      this.setState({ language: newLanguage })
                    }
                    onThemeToggle={this.props.onThemeToggle}
                  />
                )}
              />
              <Route
                path={getRoutePath("/contact")}
                render={(props) => (
                  <Contact
                    {...props}
                    theme={this.props.theme}
                    onLanguageChange={(newLanguage) =>
                      this.setState({ language: newLanguage })
                    }
                    onThemeToggle={this.props.onThemeToggle}
                  />
                )}
              />
              <Route
                path={getRoutePath("/projects")}
                render={(props) => (
                  <Projects
                    {...props}
                    theme={this.props.theme}
                    onLanguageChange={(newLanguage) =>
                      this.setState({ language: newLanguage })
                    }
                    onThemeToggle={this.props.onThemeToggle}
                  />
                )}
              />
            </Switch>
          </Router>
        </div>
      );
    }
  }
}
