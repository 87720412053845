import React, { Component } from "react";
import "./Header.css";
import { Slide } from "react-awesome-reveal";
import { NavLink, withRouter } from "react-router-dom";
import { settings } from "../../portfolio.js";
import SeoHeader from "../seoHeader/SeoHeader";
// */ import { Navbar } from "react-bootstrap";
import logo from "../../assets/logo/logo-01.png";
import whiteLogo from "../../assets/logo/logo-01_BLACK.png";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import languageIcon from "@iconify/icons-fa/language";

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};

class Header extends Component {
  handleChangeLanguage = (newLanguage) => {
    localStorage.setItem("language", newLanguage);
    const currentPath = this.props.history.location.pathname;
    const newPath = currentPath.replace(/^\/(en|es)\//, `/${newLanguage}/`);
    this.props.history.push(newPath);
    this.props.onLanguageChange(newLanguage);
    window.location.href = newPath;
  };

  render() {
    const { t } = this.props;
    const theme = this.props.theme;
    const link = settings.isSplash ? "/splash" : "home";
    const selectedLanguage = localStorage.getItem("language") || "es";
    const prefix = selectedLanguage === "es" ? "/es" : "/en";
    const logoTextWhite = this.props.theme.logoText === "white";
    const isActive = (match, location) => {
      return (
        location.pathname === `${prefix}${link}` ||
        location.pathname.startsWith(`${prefix}/education`) ||
        location.pathname.startsWith(`${prefix}/projects`) ||
        location.pathname.startsWith(`${prefix}/contact`)
      );
    };

    const LanguagePickerContainer = styled.div`
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: ${theme.body};
      border: 2px #2a2f3b solid;
      border-radius: 5px;
      padding: 8px;
      margin-right: 10px;
      transition: background 0.3s ease;
    `;

    const LanguageIcon = styled(Icon)`
      margin-right: 8px;
    `;

    const LanguageSelect = styled.select`
      cursor: pointer;
      border: none;
      outline: none;
      font-size: 16px;
      color: ${theme.text};
      background-color: ${theme.body};

      option:checked {
        cursor: pointer;
        background-color: ${theme.body};
        color: ${theme.text};
      }

      option {
        cursor: pointer;
        background-color: ${theme.body};
        color: ${theme.text};
      }
    `;

    return (
      <Slide direction="down" duration={1000}>
        <SeoHeader />
        <div>
          <header className="header" fixed="top">
            <NavLink to={link} isActive={isActive} className="logo">
              <img
                className="logo_header"
                src={logoTextWhite ? logo : whiteLogo}
                alt="Logo"
              />
            </NavLink>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn">
              <span className="navicon"></span>
            </label>
            <ul className="menu" style={{ backgroundColor: theme.body }}>
              <li>
                <NavLink
                  to={`${prefix}/home`}
                  isActive={isActive}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.blue)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  {t("nav_01")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${prefix}/education`}
                  isActive={isActive}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.blue)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  {t("nav_02")}
                </NavLink>
              </li>
              <li></li>
              <li>
                <NavLink
                  to={`${prefix}/projects`}
                  isActive={isActive}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.blue)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  {t("nav_03")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${prefix}/contact`}
                  isActive={isActive}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.blue)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  {t("nav_04")}
                </NavLink>
              </li>
            </ul>
            <LanguagePickerContainer>
              <LanguageIcon icon={languageIcon} color="orange" />
              <LanguageSelect
                name="language-picker-select"
                id="language-picker-select"
                value={selectedLanguage}
                onChange={(e) => this.handleChangeLanguage(e.target.value)}
              >
                <option lang="es" value="es" defaultValue>
                  {t("select_language-navEs")}
                </option>
                <option lang="en" value="en">
                  {t("select_language-navEn")}
                </option>
              </LanguageSelect>
            </LanguagePickerContainer>
          </header>
        </div>
      </Slide>
    );
  }
}
export default withTranslation()(withRouter(Header));
