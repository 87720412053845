import React, { Component } from "react";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import Skills from "../../containers/skills/Skills";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import { withTranslation } from "react-i18next";

class Home extends Component {
  render() {
    return (
      <div>
        <Header
          theme={this.props.theme}
          onLanguageChange={this.props.onLanguageChange}
          onToggle={this.props.onThemeToggle}
        />
        <Greeting
          theme={this.props.theme}
          language={this.props.language}
          onToggle={this.props.onThemeToggle}
        />
        <Skills theme={this.props.theme} onToggle={this.props.onThemeToggle} />
        <Footer theme={this.props.theme} onToggle={this.props.onThemeToggle} />
        <TopButton
          theme={this.props.theme}
          onToggle={this.props.onThemeToggle}
        />
      </div>
    );
  }
}

export default withTranslation()(Home);
