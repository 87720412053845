import React from "react";
import "./Greeting.css";
///*import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting, competitiveSites } from "../../portfolio";
import { Fade } from "react-awesome-reveal";
import BannerImg from "./BannerImg";
import Typewriter from "typewriter-effect";
import CompetitiveSites from "../../components/competitiveSites/CompetitiveSites";
import { useTranslation } from "react-i18next";
export default function Greeting(props) {
  const { t } = useTranslation();
  const theme = props.theme;
  const prefix = props.language === "es" ? "/es" : "/en";
  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className="greeting-text" style={{ color: theme.orange }}>
                {t("Bienvenido_msg")}
              </h1>
              <h1 className="greeting-sub" style={{ color: theme.grayText }}>
                {t("who_msg")} <mark data-entity="DEV">{greeting.sub}</mark>
              </h1>
              <h1
                className="greeting-typewriter"
                style={{ color: theme.green }}
              >
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(t("typewriter_01"))
                      .pauseFor(200)
                      .deleteAll()
                      .typeString(t("typewriter_02"))
                      .pauseFor(200)
                      .deleteAll()
                      .typeString(t("typewriter_03"))
                      .pauseFor(200)
                      .deleteAll()
                      .typeString(t("typewriter_04"))
                      .pauseFor(200)
                      .start();
                  }}
                  options={{
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h1>
              <span
                className="greeting-text-span subTitle"
                style={{ color: theme.text }}
              >
                <div className="entities">
                  {t("writer_01")}{" "}
                  <mark
                    data-entity="Identification"
                    style={{ color: theme.text }}
                  >
                    {t("writer_02")}
                  </mark>{" "}
                  {t("writer_03")}{" "}
                  <mark data-entity="action" style={{ color: theme.text }}>
                    {t("writer_04")}
                  </mark>{" "}
                  {t("writer_05")}{" "}
                  <mark data-entity="skill" style={{ color: theme.text }}>
                    back-end
                  </mark>{" "}
                  {t("writer_06")}
                  <mark data-entity="50%">{t("writer_08")}</mark>
                </div>
              </span>
              <CompetitiveSites logos={competitiveSites.competitiveSites} />
              <div className="button-greeting-div">
                <Button
                  text={t("conn_Btn")}
                  href={`${prefix}/contact`}
                  theme={theme}
                />
                {/* <Button
                  text="See my resume"
                  newTab={true}
                  href={greeting.resumeLink}
                /> */}
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
            <BannerImg />
          </div>
        </div>
      </div>
    </Fade>
  );
}
