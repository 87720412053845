import React, { Component } from "react";
import "./EventsCard.css";
import { Fade } from "react-awesome-reveal";
import { withTranslation } from "react-i18next";

class EventsCard extends Component {
  render() {
    const event = this.props.event;
    const theme = this.props.theme;
    const { t } = this.props;
    return (
      <Fade bottom duration={2000}>
        <div className="evt-card">
          <div className="content">
            <div className="content-overlay"></div>
            <div
              className="evt-header"
              style={{ backgroundColor: event.color_code }}
            >
              <img
                className="logo_img"
                src={require(`../../assets/eventos/${event.logo_path}`)}
                alt={event.alt_name}
              />
            </div>
          </div>
          <div className="evt-body">
            <h2 className="evt-body-title" style={{ color: theme.text }}>
              {t(event.title)}
            </h2>
            <h3 className="evt-body-subtitle" style={{ color: theme.orange }}>
              {event.subtitle}
            </h3>
          </div>
        </div>
      </Fade>
    );
  }
}

export default withTranslation()(EventsCard);
