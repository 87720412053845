import React, { Component } from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { Fade } from "react-awesome-reveal";
import DataScienceImg from "./datascience/DataScienceImg";
import FullStackImg from "./web/FullStackImg";
import DesignImg from "./ui/DesignImg";
import MecatronicaImg from "./mecatronica/mecatronicaimg";
import { nanoid } from "nanoid";
import { withTranslation } from "react-i18next";

function GetSkillSvg(props) {
  if (props.fileName === "DataScienceImg")
    return <DataScienceImg theme={props.theme} />;
  else if (props.fileName === "FullStackImg")
    return <FullStackImg theme={props.theme} />;
  else if (props.fileName === "MecatronicaImg")
    return <MecatronicaImg theme={props.theme} />;
  return <DesignImg theme={props.theme} />;
}

const imageAnimationConfig = {
  direction: "left",
  delay: 0,
  duration: 2000,
  easing: "ease-in-out",
  distance: "20px",
};

const textAnimationConfig = {
  direction: "right",
  delay: 0,
  duration: 1000,
  easing: "ease-in-out",
  distance: "20px",
};

class SkillSection extends Component {
  render() {
    const theme = this.props.theme;
    const { t } = this.props;
    return (
      <div>
        {skills.data.map((skill) => {
          const id = nanoid();
          return (
            <div className="skills-main-div" key={id}>
              <Fade {...imageAnimationConfig} triggerOnce>
                <div className="skills-image-div">
                  <GetSkillSvg fileName={skill.fileName} theme={theme} />
                </div>
              </Fade>
              <div className="skills-text-div">
                <Fade {...textAnimationConfig} triggerOnce>
                  <h1 className="skills-heading" style={{ color: theme.blue }}>
                    {t(skill.title)}
                  </h1>
                </Fade>
                <Fade {...textAnimationConfig} triggerOnce>
                  <SoftwareSkill logos={skill.softwareSkills} />
                </Fade>
                <Fade {...textAnimationConfig} triggerOnce>
                  <div>
                    {skill.skills.map((skillSentence, id) => {
                      return (
                        <p
                          key={id}
                          className="subTitle skills-text"
                          style={{ color: theme.text }}
                        >
                          {t(skillSentence)}
                        </p>
                      );
                    })}
                  </div>
                </Fade>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default withTranslation()(SkillSection);
