import React from "react";
import "./LoaderLogo.css";
import logo from "../../assets/logo/logo-01.png";
import whiteLogo from "../../assets/logo/logo-01_BLACK.png";
class LogoLoader extends React.Component {
  render() {
    const logoTextWhite = this.props.theme.logoText === "white";
    return <img src={logoTextWhite ? logo : whiteLogo} alt="logo" id="logo" />;
  }
}
export default LogoLoader;
