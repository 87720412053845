import React, { useEffect, useState } from "react";
import "./App.css";
import Main from "./containers/Main";
import { ThemeProvider } from "styled-components";
import { chosenTheme, lightTheme, blackTheme } from "./theme";
import { GlobalStyles } from "./global";
import ReactGA from "react-ga";
function App() {
  const initialTheme = localStorage.getItem("theme")
    ? JSON.parse(localStorage.getItem("theme"))
    : chosenTheme;

  const [theme, setTheme] = useState(initialTheme);

  const toggleTheme = () => {
    const newTheme =
      JSON.stringify(theme) === JSON.stringify(lightTheme)
        ? blackTheme
        : lightTheme;
    setTheme(newTheme);
    localStorage.setItem("theme", JSON.stringify(newTheme));
  };

  useEffect(() => {
    ReactGA.initialize("UA-199564465-1");
  });
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <div>
          <Main theme={theme} onThemeToggle={toggleTheme} />
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;
