const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Christopher Arias | Sobre Mi",
  description:
    "Desarrollador web con experiencia en tecnologías back y estudiante de mecatrônica y automatización",
  og: {
    title: "Christopher Arias",
    type: "website",
    url: "https://www.chrisaa.co",
  },
};

//Home Page
const greeting = {
  title: "Bienvenido!",
  sub: "Christopher Arias",
  logo_name: "Christopher Arias",
  resumeLink: "",
};

// home logos
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Github",
      iconifyClassname: "line-md:github-loop",
      style: {
        color: "#B14AED",
      },
      profileLink: "https://github.com/devchrisar",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/Devchrisar?hr_r=1",
    },
    {
      siteName: "Codepen",
      iconifyClassname: "simple-icons:codepen",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://codepen.io/CODER-STRING",
    },
    {
      siteName: "HashNode",
      iconifyClassname: "simple-icons:hashnode",
      style: {
        color: "#2962ff",
      },
      profileLink: "https://hashnode.com/@Devchrisar",
    },
  ],
};

//what i do?
const skills = {
  data: [
    {
      title: "skills.web_development.title",
      fileName: "FullStackImg",
      skills: [
        "skills.web_development.skill_1",
        "skills.web_development.skill_2",
        "skills.web_development.skill_3",
        "skills.web_development.skill_4",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "BootStrap",
          fontAwesomeClassname: "logos-bootstrap",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Babel",
          fontAwesomeClassname: "logos:babel",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Redux",
          fontAwesomeClassname: "simple-icons:redux",
          style: {
            color: "#764ABC",
          },
        },
        {
          skillName: "React Router",
          fontAwesomeClassname: "simple-icons:reactrouter",
          style: {
            color: "#CA4245",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "skill-icons:nextjs-dark",
          style: {
            color: "#fff",
          },
        },
        {
          skillName: "Styled Components",
          fontAwesomeClassname: "simple-icons:styled-components",
          style: {
            color: "#Ed1c24",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#48cae4",
          },
        },
        {
          skillName: "Chakra UI",
          fontAwesomeClassname: "simple-icons:chakraui",
          style: {
            color: "#81e6d9",
          },
        },
      ],
    },
    {
      title: "skills.backend_programming.title",
      fileName: "DesignImg",
      skills: [
        "skills.backend_programming.skill_1",
        "skills.backend_programming.skill_2",
        "skills.backend_programming.skill_3",
        "skills.backend_programming.skill_4",
      ],
      softwareSkills: [
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Express",
          fontAwesomeClassname: "skill-icons:expressjs-dark",
          style: {
            color: "#fff",
          },
        },
        {
          skillName: "Hapi",
          fontAwesomeClassname: "logos:hapi",
          style: {
            color: "#fff",
          },
        },
        {
          skillName: "NestJS",
          fontAwesomeClassname: "simple-icons:nestjs",
          style: {
            color: "#E0234E",
          },
        },
        {
          skillName: "MongooseJS",
          fontAwesomeClassname: "carbon:database-mongodb",
          style: {
            color: "#fe0000",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#139A4F",
          },
        },
        {
          skillName: "DynamoDB",
          fontAwesomeClassname: "simple-icons:amazondynamodb",
          style: {
            color: "#245BA9",
          },
        },
        {
          skillName: "MySql",
          fontAwesomeClassname: "logos:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "SQLite",
          fontAwesomeClassname: "simple-icons:sqlite",
          style: {
            color: "#003B57",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#4169E1",
          },
        },
        {
          skillName: "Golang",
          fontAwesomeClassname: "simple-icons:go",
          style: {
            color: "#69D7E4",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#FFCD40",
          },
        },
        {
          skillName: "FastAPI",
          fontAwesomeClassname: "simple-icons:fastapi",
          style: {
            color: "#009688",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#092E20",
          },
        },
        {
          skillName: "RabbitMQ",
          fontAwesomeClassname: "simple-icons:rabbitmq",
          style: {
            color: "#FF6600",
          },
        },
        {
          skillName: "Kafka",
          fontAwesomeClassname: "skill-icons:kafka",
          style: {
            color: "#fff",
          },
        },
        {
          skillName: "Redis",
          fontAwesomeClassname: "simple-icons:redis",
          style: {
            color: "#DC382D",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#10A0DC",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#356DE6",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#E20097",
          },
        },
        {
          skillName: "Sequelize",
          fontAwesomeClassname: "simple-icons:sequelize",
          style: {
            color: "#52B0E7",
          },
        },
        {
          skillName: "Socket.IO",
          fontAwesomeClassname: "cib:socket-io",
          style: {
            color: "#ff0054",
          },
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "vscode-icons:file-type-jenkins",
          style: {
            color: "#D24939",
          },
        },
        {
          skillName: "Deno",
          fontAwesomeClassname: "skill-icons:deno-dark",
          style: {
            color: "#ffef",
          },
        },
        {
          skillName: "Json Web Tokens",
          fontAwesomeClassname: "simple-icons:jsonwebtokens",
          style: {
            color: "#560bad",
          },
        },
        {
          skillName: "Auth0",
          fontAwesomeClassname: "simple-icons:auth0",
          style: {
            color: "#EB5424",
          },
        },
        {
          skillName: "MochaJS",
          fontAwesomeClassname: "vscode-icons:file-type-mocha",
          style: {
            color: "#fff",
          },
        },
        {
          skillName: "Jest",
          fontAwesomeClassname: "vscode-icons:file-type-jest",
          style: {
            color: "#EB5424",
          },
        },
        {
          skillName: "SonarQube",
          fontAwesomeClassname: "devicon:sonarqube",
          style: {
            color: "#000",
          },
        },
        {
          skillName: "Swagger",
          fontAwesomeClassname: "logos:swagger",
          style: {
            color: "#000",
          },
        },
      ],
    },
    {
      title: "skills.motion_designer.title",
      fileName: "DataScienceImg",
      skills: [
        "skills.motion_designer.skill_1",
        "skills.motion_designer.skill_2",
        "skills.motion_designer.skill_3",
      ],
      softwareSkills: [
        {
          skillName: "Blender",
          fontAwesomeClassname: "logos-blender",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Cinema 4D",
          fontAwesomeClassname: "simple-icons:cinema4d",
          style: {
            color: "#4956C9",
          },
        },
        {
          skillName: "Autodesk Maya",
          fontAwesomeClassname: "vscode-icons:file-type-maya",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "skills.mechatronics.title",
      fileName: "MecatronicaImg",
      skills: [
        "skills.mechatronics.skill_1",
        "skills.mechatronics.skill_2",
        "skills.mechatronics.skill_3",
      ],
      softwareSkills: [
        {
          skillName: "Neumática",
          fontAwesomeClassname: "icon-park-outline:tire-swing",
          style: {
            color: "#540d6e",
          },
        },
        {
          skillName: "Hidráulica",
          fontAwesomeClassname: "ic:baseline-water-drop",
          style: {
            color: "#006992",
          },
        },
        {
          skillName: "Electronica",
          fontAwesomeClassname: "emojione-monotone:electric-plug",
          style: {
            color: "#ffd23f",
          },
        },
        {
          skillName: "Mecánica",
          fontAwesomeClassname: "bx:bxs-car-mechanic",
          style: {
            color: "#DAD7CD",
          },
        },
        {
          skillName: "Robótica",
          fontAwesomeClassname: "ph:robot-fill",
          style: {
            color: "#FF5400",
          },
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "nav_03",
  description: "pro_01",
};

//TODO: CAMBIAR TRADUCCION */
//project cards
// colors:[primary,secondary,success,danger,warning,info,light,dark,]
const projectcards = {
  list: [
    {
      title: "Star⭐Petal",
      img_path: "starpetal.png",
      description: "projects.list.description_01",
      tags: [
        {
          lang: "react",
          color: "#004782",
        },
        {
          lang: "javascript",
          color: "#820032",
        },
        {
          lang: "stripe",
          color: "#00a100",
        },
        {
          lang: "Heroku",
          color: "#3c0095",
        },
      ],
      link: "https://www.youtube.com/watch?v=59pJ6YRlmYc",
      code: "https://github.com/devchrisar/starpetal",
      linkcolor: "white",
    },

    {
      title: "react note markdown",
      img_path: "project-05.png",
      description: "projects.list.description_02",
      tags: [
        {
          lang: "stalled",
          color: "red",
        },
        {
          lang: "react",
          color: "#004782",
        },
        {
          lang: "javascript",
          color: "#c47206",
        },
        {
          lang: "nanoID",
          color: "#4257f5",
        },
        {
          lang: "netifly",
          color: "#598976",
        },
      ],
      code: "https://github.com/devchrisar/react-note_markdown-app",
      linkcolor: "white",
    },
    {
      title: "A Big Knockout",
      img_path: "abk.gif",
      description: "projects.list.description_03",
      tags: [
        {
          lang: "html",
          color: "#004782",
        },
        {
          lang: "css",
          color: "#8700b0",
        },
        {
          lang: "javascript",
          color: "#c47206",
        },
      ],
      link: "https://devchrisar.github.io/AbigKnockout/",
      code: "https://github.com/devchrisar/AbigKnockout",
      linkcolor: "white",
    },
    {
      title: "Gotapes",
      img_path: "Gotapes.svg",
      description: "projects.list.description_04",
      tags: [
        {
          lang: "ongoing",
          color: "green",
        },
        {
          lang: "scss",
          color: "#ff8fab",
        },
        {
          lang: "javascript",
          color: "#CC5500",
        },
        {
          lang: "react",
          color: "#006699",
        },
        {
          lang: "chakraUI",
          color: "#009933",
        },
        {
          lang: "Golang",
          color: "#00ADD8",
        },
        {
          lang: "MongoDB",
          color: "#47A248",
        },
        {
          lang: "RabbitMQ",
          color: "#FF6600",
        },
        {
          lang: "jwt",
          color: "#990099",
        },
        {
          lang: "echo",
          color: "#00CCCC",
        },
      ],
      link: "https://youtu.be/R6VAgxnkJmA",
      code: "https://github.com/devchrisar/Gotapes/tree/main",
      linkcolor: "white",
    },
  ],
};

const resumeleft = {
  left: {
    title: ["resumeleft.title"],
    data: [
      {
        id: "junior-developer",
        title: "resumeleft.data.title",
        subtitle: "resumeleft.data.subtitle",
        date: "resumeleft.data.date",
        content: ["resumeleft.data.content"],
      },
      // {
      //   id: "some-id-here (change)",
      //   title: "resumeleft.data.title",
      //   subtitle: "resumeleft.data.subtitle",
      //   date: "resumeleft.data.date",
      //   content: [
      //     "resumeleft.data.content",
      //   ],
      // },
    ],
  },
};

const resumeright = {
  right: [
    {
      title: ["resumeright.title"],
      data: [
        {
          id: "Ingenieria-en-Mecatronica",
          title: "resumeright.data.title_01",
          subtitle: "resumeright.data.subtitle_01",
          date: "resumeright.data.date_01",
          content: ["resumeright.data.content_01"],
        },
        {
          id: "tecnico-en-programacion",
          title: "resumeright.data.title_02",
          subtitle: "resumeright.data.subtitle_02",
          date: "resumeright.data.date_02",
          content: ["resumeright.data.content_02"],
        },
        {
          id: "Tecnología-en-Automatización-Industrial",
          title: "resumeright.data.title_03",
          subtitle: "resumeright.data.subtitle_03",
          date: "resumeright.data.date_03",
          content: ["resumeright.data.content_03"],
        },
        {
          id: "Certificacion-de-800-horas-en-formacion-de-programacion",
          title: "resumeright.data.title_04",
          subtitle: "resumeright.data.subtitle_04",
          date: "resumeright.data.date_04",
          content: [
            "resumeright.data.content_04.line1",
            "resumeright.data.content_04.line2",
            "resumeright.data.content_04.line3",
            "resumeright.data.content_04.line4",
            "resumeright.data.content_04.line5",
          ],
        },
        {
          id: "Pregrado-en-Profesional-en-Actividad-Fisica-y- Deporte",
          title: "resumeright.data.title_05",
          subtitle: "resumeright.data.subtitle_05",
          date: "resumeright.data.date_05",
          content: ["resumeright.data.content_05"],
        },
        {
          id: "Bachiller-Academico",
          title: "resumeright.data.title_06",
          subtitle: "resumeright.data.subtitle_06",
          date: "resumeright.data.date_06",
          content: ["resumeright.data.content_06"],
        },
        {
          id: "Pregrado-en-Contaduria-Publica",
          title: "resumeright.data.title_07",
          subtitle: "resumeright.data.subtitle_07",
          date: "resumeright.data.date_07",
          content: ["resumeright.data.content_07"],
        },
        {
          id: "Bachiller-en-Ciencias",
          title: "resumeright.data.title_08",
          subtitle: "resumeright.data.subtitle_08",
          date: "resumeright.data.date_08",
          content: ["resumeright.data.content_08"],
        },
      ],
    },
    {
      title: ["resumeright.title_02"],
      data: [
        {
          title: "resumeright.data.title_09",
          content: ["resumeright.data.content_09"],
        },
      ],
    },
  ],
};

//certificate cards
const certifications = {
  certifications: [
    {
      title: "certifications.title_01",
      subtitle: "MisionTic - Universidad Pontificia Bolivariana UPB",
      logo_path: "minTic.png",
      certificate_link:
        "https://drive.google.com/drive/folders/168FJ3vxJIQ_abF5Kt0qBa-CYLmyJvpDN?usp=sharing",
      alt_name: "misiontic",
      color_code: "#000000",
    },
    {
      title: "certifications.title_02",
      subtitle: "Coderhouse",
      logo_path: "coderhouse.png",
      certificate_link:
        "https://drive.google.com/drive/folders/1qFSWXAW04oJSNrc2-6mSfpAUe_4WlF3v?usp=sharing",
      alt_name: "coderhouse",
      color_code: "#000000",
    },
    {
      title: "certifications.title_03",
      subtitle: "CodigoFacilito",
      logo_path: "codigofacilito.png",
      certificate_link:
        "https://drive.google.com/file/d/1PyTelnC5gZgiqVpsARGABSPqSk5AaSlE/view?usp=sharing",
      alt_name: "CodigoFacilito",
      color_code: "#000000",
    },
    {
      title: "certifications.title_04",
      subtitle: "CertiProf",
      logo_path: "certiprof.png",
      certificate_link:
        "https://drive.google.com/file/d/1lWEijm_4YqxiksesX0UY5E5PPn928NPG/view?usp=sharing",
      alt_name: "CertiProf",
      color_code: "#000000",
    },
    {
      title: "certifications.title_05",
      subtitle: "OpenWebinars",
      logo_path: "openwebinar.png",
      certificate_link:
        "https://drive.google.com/file/d/1ofdQLiZelC6BI7msNW_yrpB86FFxM9PI/view?usp=sharing",
      alt_name: "OpenWebinars",
      color_code: "#000000",
    },
    {
      title: "certifications.title_06",
      subtitle: "DataCamp",
      logo_path: "datacamp-01.png",
      alt_name: "DataCamp",
      color_code: "#000000",
    },
    {
      title: "certifications.title_07",
      subtitle: "Udemy",
      logo_path: "udemy-01.png",
      alt_name: "Udemy",
      color_code: "#000000",
    },
    {
      title: "certifications.title_08",
      subtitle: "Coursera",
      logo_path: "coursera-01.png",
      alt_name: "Coursera",
      color_code: "#000000",
    },
    {
      title: "certifications.title_09",
      subtitle: "OpenWebinars",
      logo_path: "openwebinar.png",
      alt_name: "OpenWebinars",
      color_code: "#000000",
    },
    {
      title: "certifications.title_10",
      subtitle: "Instituto Colombiano De Robotica Avanzada ICRA",
      logo_path: "udemy-01.png",
      alt_name: "ICRA",
      color_code: "#000000",
    },
    {
      title: "certifications.title_11",
      subtitle: "OpenWebinars",
      logo_path: "openwebinar.png",
      alt_name: "OpenWebinars",
      color_code: "#000000",
    },
    {
      title: "certifications.title_12",
      subtitle: "EdTeam",
      logo_path: "edteam.png",
      alt_name: "EdTeam",
      color_code: "#000000",
    },
    {
      title: "certifications.title_13",
      subtitle: "OpenWebinars",
      logo_path: "openwebinar.png",
      alt_name: "OpenWebinars",
      color_code: "#000000",
    },
    {
      title: "certifications.title_14",
      subtitle: "iNeuron.ai",
      logo_path: "ineuron-01.png",
      alt_name: "iNeuron",
      color_code: "#000000",
    },
    {
      title: "certifications.title_15",
      subtitle: "PluralSight",
      logo_path: "pluralsight.png",
      alt_name: "PluralSight",
      color_code: "#000000",
    },
    {
      title: "certifications.title_16",
      subtitle: "Instituticion Universitaria Digital De Antioquia IUD",
      logo_path: "iudigital.png",
      alt_name: "IUD",
      color_code: "#000000",
    },
    {
      title: "certifications.title_17",
      subtitle: "CodeAcademy",
      logo_path: "codeacademy.png",
      alt_name: "CodeAcademy",
      color_code: "#000000",
    },
  ],
};
//events cards
const events = {
  events: [
    {
      title: "event_01.title",
      subtitle: "TalendLand",
      logo_path: "TalendLandDigital.png",
      alt_name: "talendland",
      color_code: "#000000",
    },
    {
      title: "event_02.title",
      subtitle: "JsNation",
      logo_path: "jsnation.png",
      alt_name: "jsnation",
      color_code: "#000000",
    },
    {
      title: "event_03.title",
      subtitle: "nodeCongress",
      logo_path: "node.png",
      alt_name: "nodeCongress",
      color_code: "#000000",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "conc_01",
    profile_image_path: "profile.jpg",
    description: "conc_02",
  },
  addressSection: {
    title: "conc_03",
    subtitle: "Medellin, Colombia	\ud83c\udde8\ud83c\uddf4",
  },
  phoneSection: {
    title: "conc_04",
    subtitle: "+1 (786) 699-4909",
  },
};

//contact icons
const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/devchrisar",
    iconifyClassname: "simple-icons:hackerrank",
    fontAwesomeIcon: "fa-github",
    backgroundColor: "#7d8597",
  },
  {
    name: "Gitlab",
    link: "https://gitlab.com/christopher.ariasar",
    fontAwesomeIcon: "fa-gitlab",
    backgroundColor: "#ffb703",
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/channel/UCcykSegq3_j6BW-vcFR3Qdg",
    fontAwesomeIcon: "fa-youtube",
    backgroundColor: "#FF0000",
  },
  {
    name: "Bitbucket",
    link: "https://bitbucket.org/Devchrisar/",
    fontAwesomeIcon: "fa-bitbucket",
    backgroundColor: "#0077B5",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/sadfacezz/",
    fontAwesomeIcon: "fa-instagram",
    backgroundColor: "#E4405F",
  },
  {
    name: "Linkedin",
    link:
      "https://www.linkedin.com/in/christopher-henry-arias-arcia-758382228/",
    fontAwesomeIcon: "fa-linkedin",
    backgroundColor: "#0077B5",
  },
  {
    name: "Twitter",
    link: "https://twitter.com/Devchrisar",
    fontAwesomeIcon: "fa-twitter",
    backgroundColor: "#1DA1F2",
  },
  {
    name: "Discord",
    link: "https://discord.com/users/691048812558417992",
    fontAwesomeIcon: "fa-discord",
    backgroundColor: "#613dc1",
  },
  {
    name: "Telegram",
    link: "https://t.me/PUN0PUN",
    fontAwesomeIcon: "fa-telegram",
    backgroundColor: "#90e0ef",
  },
];

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  projectsHeader,
  contactPageData,
  projectcards,
  certifications,
  events,
  resumeleft,
  resumeright,
};
