// import lottie from 'lottie-web';
// import { Component } from "react";
// import banner from "./greeting-01.svg"
import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import Robot from "./Robot";

export default function BannerImg() {
  return (
    <Canvas
      className="container"
      width={1400}
      height={1400}
      camera={{ fov: 20, near: 0.1, far: 1000, position: [15, 0, 0] }}
    >
      <OrbitControls />
      <directionalLight intensity={0.5} />
      <ambientLight intensity={0.2} />
      <Suspense fallback={null}>
        <Robot />
      </Suspense>
    </Canvas>
  );
}

//! LOTTIE JS O SVG animated use json file (BANNER)
// import lottie from 'lottie-web';
// import React, {useRef,useEffect } from "react";
// import { Component } from "react";
// import banner from "./greeting-01.svg"

//for animated svgs use json file
// export default function BannerImg(){
//     const container = useRef(null)
//     useEffect(() => {
//       lottie.loadAnimation({
//         container: container.current,
//         renderer: 'svg',
//         loop: true,
//         autoplay: true,
//         animationData: require('./banner.json')
//       })
//     }, [])
//     return (
//       <div className="App">
//         <div className="container" ref={container}></div>
//       </div>
//     );
// }

//*for static svgs
// class BannerImg extends Component {
//   render() {
//     return (
//       <div className="App">
//         <div className="container">
//       <img src={banner} alt="" width="550" />
//       </div>
//       </div>
//     );
//   }
// }
// export default BannerImg;
