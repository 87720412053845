import React, { Component } from "react";
import "./Certifications.css";
import { Fade } from "react-awesome-reveal";
import { certifications, events } from "../../portfolio";
import CertificationCard from "../../components/certificationCard/CertificationCard";
import EventsCard from "../../components/eventsCard/EventsCard";
import { withTranslation } from "react-i18next";

class Certifications extends Component {
  render() {
    const theme = this.props.theme;
    const { t } = this.props;
    return (
      <div className="main" id="certs">
        <div className="certs-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="certs-header" style={{ color: theme.text }}>
              {t("exp_02")}
            </h1>
          </Fade>
        </div>
        <div className="certs-body-div">
          {certifications.certifications.map((cert) => {
            return (
              <CertificationCard
                key={cert.title}
                certificate={cert}
                theme={theme}
              />
            );
          })}
        </div>
        <div className="certs-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="certs-header" style={{ color: theme.text }}>
              {t("exp_03")}
            </h1>
          </Fade>
        </div>
        <div className="certs-body-div">
          {events.events.map((evt) => {
            return <EventsCard key={evt.title} event={evt} theme={theme} />;
          })}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Certifications);
