import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Resumecontent from "./ResumeContent";
import { resumeleft, resumeright } from "../../portfolio";
import "./EducationComponent.css";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";

export default function Resume({ theme, onThemeToggle }) {
  const { t } = useTranslation();
  return (
    <Container fluid className="resume-section">
      <Container>
        <Row className="resume">
          <div className="heading-div">
            <Col md={6} className="resume-left">
              {resumeleft.left.title.map((head) => (
                <div key={nanoid()}>
                  <h3 className="resume-title" style={{ color: theme.text }}>
                    {t(head)}
                  </h3>
                  {resumeleft.left.data.map((res) => (
                    <Resumecontent
                      theme={theme}
                      onThemeToggle={onThemeToggle}
                      key={res.id}
                      title={t(res.title)}
                      subtitle={t(res.subtitle)}
                      date={t(res.date)}
                      content={t(res.content)}
                    />
                  ))}
                </div>
              ))}
            </Col>
            <Col md={6} className="resume-right">
              {resumeright.right.map((head, index) => (
                <div key={index}>
                  <h3 className="resume-title" style={{ color: theme.text }}>
                    {t(head.title)}
                  </h3>
                  {head.data.map((info, infoIndex) => (
                    <Resumecontent
                      theme={theme}
                      onThemeToggle={onThemeToggle}
                      key={`${index}-${infoIndex}`}
                      title={t(info.title)}
                      subtitle={t(info.subtitle)}
                      date={t(info.date)}
                      content={info.content.map((contentKey) => t(contentKey))}
                    />
                  ))}
                </div>
              ))}
            </Col>
          </div>
        </Row>
      </Container>
    </Container>
  );
}
